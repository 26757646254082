import { createWebHistory, createRouter } from "vue-router";
import { trackRouter } from "vue-gtag-next";
import store from "../store";
import axios from 'axios'
import {trackerLeadSquared,trackerFBPixel} from '@/assets/js/TinkerlyTracking.js';
const routes = [
    {
        path: "/zoom/:username",
        redirect: async (to) => {
            var datas = to.params.username.split(":");
            if(datas.length!=2)
            return "/";
            if(datas[0].length<5 || datas[1].length<5)
            return "/";

           var response =  await axios.get('https://lt.tinker.ly/api/v1/GetZoomJoinLink/'+datas[0]+'/'+datas[1]);
           if(response.status==200 && response.data.length>10)
                window.location = response.data;
            else 
            return "/";
          }},
    {
        path: "/:username?",
        name: "Home",
        component: () => import('@/components/ReDesign/Home.vue'),
    },
    {
        path: "/school/:username?",
        name: "School",
        component: () => import('@/components/School.vue'),
    },
    {
        path: "/call-request/:username?",
        name: "CallRequest",
        component: () => import('@/components/RequestCall.vue'),
    },
    {
        path: "/sa/:username?",
        name: "SaHome",
        component: () => import('@/components/international/Home.vue'),
    },
    {
        path: "/sa-ar/:username?",
        name: "SaarHome",
        component: () => import('@/components/international/HomeRTL.vue'),
    },
    {
        path: "/book-demo/:username?",
        name: "BookDemo",
        component: () => import('@/components/ReDesign/BookDemo.vue'),
    },
    {
        path: "/pay-now/:username?",
        name: "PayNow",
        component: () => import('@/components/BuyNow.vue'),
    },
    {
        path: "/book-demo-sa/:username?",
        name: "BookDemoSA",
        component: () => import('@/components/BookDemo.vue'),
    },
    {
        path: "/book-demo-ar/:username?",
        name: "BookDemoAR",
        component: () => import('@/components/international/BookDemoRTL.vue'),
    },
    {
        path: "/pay-now-ar/:username?",
        name: "PayNowAR",
        component: () => import('@/components/international/BuyNowRTL.vue'),
    },
    {
        path: "/Privacy-Policy",
        name: "PrivacyPolicy",
        component: () => import('@/components/PrivacyPolicy.vue'),
    }

];

const router = createRouter({
    history: createWebHistory(),
    routes,
});
trackRouter(router);
 router.beforeEach((to, from, next) => {
    const html = document.documentElement // returns the html tag
    const descEl = document.querySelector('head meta[name="description"]');
    const titleEl = document.querySelector('head title');
    switch (to.name){
        case 'Home':
            html.setAttribute('lang', 'en');
    html.setAttribute('dir', 'ltr');
    descEl.setAttribute('content', 'Tinkerly provides online coding courses blended with hands-on STEM toys for kids of grades 1-12. This coding course for kids consists of 1:1 & 1:5 live online classes by expert teachers. Learn to make real-life projects of AI, IoT and robotics..');
titleEl.textContent = 'Tinkerly – Best Coding Courses for Kids | STEM Classes for Kids';
            break;
            case 'School':
                html.setAttribute('lang', 'en');
    html.setAttribute('dir', 'ltr');
    descEl.setAttribute('content', 'Tinkerly supports K-12 schools to offer subjects like Coding, AI, and Robotics as per NEP Guidelines , Get 2 weeks of free classes for all students and 24x7 Doubt Support by our Experts.');
titleEl.textContent = 'Coding for school | AI and Robotics | Tinkerly';
            break;
            case 'SaHome':
                html.setAttribute('lang', 'en');
    html.setAttribute('dir', 'ltr');
    descEl.setAttribute('content',"Tinkerly provides online coding courses with hands-on STEM toys for kids of grades 1-12. We'll provide 1:1 & 1:5 live online classes by expert teachers. Learn to make real-life projects of AI, IoT and robotics..");
titleEl.textContent = 'Online Classes on AI, Robotics | STEM Classes for Kids | Tinkerly';
            break;
            case 'BookDemo':
                html.setAttribute('lang', 'en');
    html.setAttribute('dir', 'ltr');
    descEl.setAttribute('content', "Tinkerly provides online STEM classes for kids blended with hands-on STEM toys for kids of grades 1-12. Online coding course for kids consists of 1:1 & 1:5 live online classes by expert teachers. Learn to make real-life projects of AI, IoT and robotics..");
titleEl.textContent = "STEM Classes for Kids | Book demo | Tinkerly";
            break;
            case 'PayNow':
                html.setAttribute('lang', 'en');
    html.setAttribute('dir', 'ltr');
    descEl.setAttribute('content', "Explore the Kids Programming Courses Offered By Tinkerly, Enroll For A Free Trial. Live 1:1 Personalised STEM classes for kids. Book a Trial Class Now. Secured For Kids. also 1:1 Live Video Classes. Book Now.");
titleEl.textContent = "STEM Classes for Kids | Pay Now | Tinkerly";
            break;
            case 'SaarHome':
                html.setAttribute('lang', 'ar');
    html.setAttribute('dir', 'rtl');
    descEl.setAttribute('content', 'Tinkerly provides online coding courses blended with hands-on STEM toys for kids of grades 1-12. This coding course for kids consists of 1:1 & 1:5 live online classes by expert teachers. Learn to make real-life projects of AI, IoT and robotics..');
    titleEl.textContent = 'Tinkerly – Best Coding Courses for Kids | STEM Classes for Kids';
                break;
            case 'BookDemoAR':
                html.setAttribute('lang', 'ar');
    html.setAttribute('dir', 'rtl');
    descEl.setAttribute('content', 'Tinkerly provides online coding courses blended with hands-on STEM toys for kids of grades 1-12. This coding course for kids consists of 1:1 & 1:5 live online classes by expert teachers. Learn to make real-life projects of AI, IoT and robotics..');
    titleEl.textContent = 'Tinkerly – Best Coding Courses for Kids | STEM Classes for Kids';
            break;
            case 'PayNowAR':
                html.setAttribute('lang', 'ar');
    html.setAttribute('dir', 'rtl');
    descEl.setAttribute('content', 'Tinkerly provides online coding courses blended with hands-on STEM toys for kids of grades 1-12. This coding course for kids consists of 1:1 & 1:5 live online classes by expert teachers. Learn to make real-life projects of AI, IoT and robotics..');
    titleEl.textContent = 'Tinkerly – Best Coding Courses for Kids | STEM Classes for Kids';
            break;
    }
    if(store.state.country.length<1){
        axios.get('https://ipinfo.io/?token=7759c1a2fb117f').
        then((response) => {return response.data})
            .then(data => {
                store.commit("setCountry",data.country);
                if(data.country != "IN"){
        var bodyFormData = new FormData();
        bodyFormData.append('json', data.country);
        axios.post('https://lt.tinker.ly/api/V1/GetAmountMulti',bodyFormData).then(res=>{return res.data})
        .then(d=>{
            store.commit("setMultiplayer",d.data);
            
        })
                }
                if(store.state.country!="IN" ){
                    if(to.name=="Home")
                        next("/sa"+to.path);
                        else if(to.name=="BookDemo")
                        next("/book-demo-sa"+to.path);
                        else next()
                }
        
        else
        next();
            });
    }
    else{
        if(store.state.country!="IN"){
            if(to.name=="Home")
                next("/sa"+to.path);
                else if(to.name=="BookDemo")
                next("/book-demo-sa"+to.path);
                else next()
        }
        else
        next();
    }
    
   })

   router.afterEach(()=>{     
    trackerLeadSquared();
       trackerFBPixel('PageView');
   });
export default router;