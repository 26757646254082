<template>
  <router-view />
</template>

<script>
   

export default {
  name: 'App',
  }
</script>
<style >
.vcp__header, .vcpg {
        background-color: transparent !important;
        border-color: transparent !important;
    }
    .vcp__header {
        color: #FF6666;
        font-weight:600;
        height: auto !important;
    }
        .vcp__header:before {
            content: 'o';
            color: #000;
            display: inline-block;
            width: 30px;
            font-weight:900;
        }
        .vcp__header-title {
            font-weight: 600 !important;
        }
    .vcp__body {
        margin-left: 50px;
        
    }
    .vcp__body-content {
        padding: 18px !important;
    }
    .row{
        overflow: clip;
    }
</style>


