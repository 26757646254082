import { createStore } from 'vuex'


const store = createStore({
   state:{
    
      multipllayer :3.75,
      displayc:"SAR",
      country:"",
      grade:'',
      mobileNumber:'',
      mode:''

   },
   mutations: {
     setCountry (state,c) {
       state.country = c;
     },
     setMultiplayer (state,d) {       
        state.displayc = d.currencyUse;
        state.multipllayer = d.displayMultiplier;
      },
      setGrade(state,c){
         state.grade = c;
      },
      setMobileNumber(state,c){
         state.mobileNumber = c;
      }
      ,
      setMode(state,c){
         state.mode = c;
      }
   }
});

export default store