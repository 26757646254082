/* eslint-disable */

export function trackerLeadSquared() {
    pidTracker(50529);
}
export function trackerLeadSquaredEvent(n) {
    logMXWebEvent(50529, n);
}

export function getMXCProspectId() {
    if (typeof (MXCProspectId) !== "undefined")
        return MXCProspectId;
    else return null;
}

export function trackerFBPixel(event) {
    fbq('track', event);
}
/* eslint-enable */

import axios from 'axios'
export function lsdTracking(form, route) {
    var leadOptions = { headers: { "Content-Type": "application/json" } };
    var leadURL =
        "https://api-in21.leadsquared.com/v2/LeadManagement.svc/Lead.Capture?accessKey=u$r33f4bf885fac45bf9fd2cb770d2cb54e&secretKey=63c3b1df9c6a723e3dd5bdc663b27b8f6aca7158";
    var ctype = "mx_Kids_Age";
    var mx_Lead_Type = "Int. User";
    var Source = "Web-SA";
    var gradeType = 'Age';
    if (form.FormatMobile.startsWith("+91-")) {
        ctype = "mx_Kid_Grade";
        mx_Lead_Type = "User";
        Source = "Web-India";
        gradeType = 'Grade';
    }
    var d = [
        { Attribute: "mx_Lead_Type", Value: mx_Lead_Type },
        { Attribute: "Source", Value: Source },
        { Attribute: "mx_Grade_Type", Value: gradeType },
        { Attribute: "ProspectStage", Value: 'Prospect' },
    ];
    if (form.FormatMobile != null && form.FormatMobile != undefined)
        d.push({ Attribute: "Phone", Value: form.FormatMobile });
    if (form.Name != null && form.Name != undefined)
        d.push({ Attribute: "FirstName", Value: form.Name });
    if (form.Grade != null && form.Grade != undefined)
        d.push({ Attribute: ctype, Value: form.Grade });
    if (form.OtpVerify != null && form.OtpVerify != undefined)
        d.push({ Attribute: 'mx_otp_verify', Value: form.OtpVerify });
    if (form.Email != null && form.Email != undefined)
        d.push({ Attribute: 'EmailAddress', Value: form.Email });

    let { utm_source, utm_medium, utm_campaign } = route.query;
    if (utm_source != null)
        d.push({ Attribute: "Source", Value: utm_source });
    if (utm_campaign != null)
        d.push({ Attribute: "SourceCampaign", Value: utm_campaign });
    if (utm_medium != null)
        d.push({ Attribute: "SourceMedium", Value: utm_medium });
    if (getMXCProspectId() != null)
        d.push({ Attribute: "ProspectID", Value: getMXCProspectId() });
    axios.post(leadURL, d, leadOptions).then(() => {});
}

