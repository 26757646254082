import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
import { VueWindowSizePlugin } from 'vue-window-size/option-api';
import axios from 'axios'
import VueAxios from 'vue-axios'
import './registerServiceWorker'
import store from './store/index' 
import VueGtag from "vue-gtag-next";
import Vue3VideoPlayer from '@cloudgeek/vue3-video-player'
import '@cloudgeek/vue3-video-player/dist/vue3-video-player.css'

createApp(App).use(router).use(store).use(VueWindowSizePlugin).use(VueAxios, axios).use(VueGtag, {
    property: [
      {
        id:"UA-70712432-9"
      },
        {
            id: "AW-810557807"
            
        },
      
    ],
    useDebugger: true
  }).use(Vue3VideoPlayer).mount('#app')
